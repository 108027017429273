/**
 * The webcam app
 * Christian Kuster, CH-8342 Wernetshausen, christian@kusti.ch
 * Created: 14.02.23
 **/

import {createApp} from 'vue'
import PrimoTest from './components/webcam-app.vue';

// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import {createVuetify} from 'vuetify'
import {aliases, mdi} from 'vuetify/iconsets/mdi'

const vuetify = createVuetify({
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets      : {
            mdi,
        }
    }
})


// Pinia
import {createPinia} from 'pinia'

const pinia = createPinia();

const app = createApp(PrimoTest);
app.use(vuetify);
app.use(pinia);
app.mount('#app')

/**
 * Store for the webcam
 * Christian Kuster, CH-8342 Wernetshausen, christian@kusti.ch
 * Created: 15.02.23
 **/

import {defineStore} from 'pinia'
import axios from "axios";
import {DateTime} from "luxon";

export const useWebcamStore = defineStore('webcam', {
  state: () => ({
    images: []
  }),
  actions: {
    async fetchImages() {
      const self = this;
      axios.get('https://webcam.kusti.ch/api.php?list=1').then(resp => {
        resp.data.forEach(file => {
          let d = DateTime.fromFormat(file, "yyyyMMdd'T'hhmmss'.jpg'");
          self.images.push({title: d.toLocaleString(DateTime.DATETIME_SHORT), file:file});
        })
      }).catch(err => {
        console.error(err);
      })
    }
  }
})

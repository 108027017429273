/**
 * Store for meteo data
 * Christian Kuster, CH-8342 Wernetshausen, christian@kusti.ch
 * Created: 16.02.23
 **/

import {defineStore} from 'pinia'
import axios from "axios";

export const useMeteoStore = defineStore('meteo', {
  state: () => ({
    current: {
      temperature: 0,
      humidity: 0,
      windspeed: 0,
      pressure: 0,
      rainrate: 0,
    },
    mean: {
      temperature: 0,
      humidity: 0,
      windspeed: 0,
      pressure: 0,
      rainrate: 0,
    },
    trend: {
      temperature: 0,
      humidity: 0,
      windspeed: 0,
      pressure: 0,
      rainrate: 0,
    },
    loaded: false
  }),
  actions: {
    /**
     * Fetch meteo data from the api
     * @returns {Promise<void>}
     */
    async fetchData() {
      const self = this;

      /**
       * Calculates the mean value
       * @param arr
       * @param key
       * @returns {number}
       */
      function calculateMean(arr, key) {
        let sum = 0;
        arr.forEach(e => {
          sum += parseFloat(e[key]);
        })
        return sum / arr.length;
      }

      /**
       * Sets the value for a key
       * @param arr
       * @param key
       */
      function setValues(arr, key) {
        self.current[key] = parseFloat(arr[0][key]);
        self.mean[key] = calculateMean(arr, key);
      }

      function setTrend(key, plusPlus, plus, minus, minusMinus) {
        const diff = self.current[key] - self.mean[key];
        if (diff >= plusPlus) {
          self.trend[key] = 2;
        } else if (diff >= plus) {
          self.trend[key] = 1
        } else if (diff <= minusMinus) {
          self.trend[key] = -2
        } else if (diff <= minus) {
          self.trend[key] = -1
        } else {
          self.trend[key] = 0;
        }
      }

      axios.get('https://webcam.kusti.ch/api.php?meteo=1').then(resp => {
        console.log(resp);
        setValues(resp.data, 'temperature');
        setValues(resp.data, 'humidity');
        setValues(resp.data, 'pressure');
        setValues(resp.data, 'windspeed');
        setValues(resp.data, 'rainrate');
        setTrend('temperature', 2, 1, -1, -2);
        setTrend('humidity', 5, 1, -1, -5);
        setTrend('pressure', 2, 0.3, -0.3, -2);
        setTrend('rainrate', 5, 2, -2, -5);
        setTrend('windspeed', 5, 2, -2, -5);
        self.loaded = true;
      }).catch(err => {
        console.error(err);
      })
    }
  }
})
